let widgetData = {
  expectedOrigin: '',
  clientKey: '',
  wlSlug: '',
  merchantSlug: '',
  containerFound: false,
  containerId: '',
  invalidClientKey: 'Oops! We encountered an issue validating your client key. Please double-check your key and try again.',
  urlOverride: ''
};

/**
 * Creates an iframe widget and appends it to the specified container.
 * @param {string} clientKey - Merchant client key.
 * @param {string} containerId - The ID of the container where the iframe should be appended.
 * @param {Function} callback - The callback function to execute when a message is received.
 * @param {any} formData Applicant's data.
 */
function setup(clientKey, containerId, callback, formData) {
  widgetData.clientKey = clientKey;
  widgetData.containerId = containerId;
  var container = document.getElementById(containerId);
  if (!container) {
    const containerErrorMessage = 'Container not found: ' + containerId;
    displayEnableWidgetError(containerErrorMessage);
  } else {
    widgetData.containerFound = true;
    fetch(`${process.env.REACT_APP_DB_URL}customer/merchant/merchant-slug-info/${clientKey}/?${(new Date()).getTime()}`)
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          widgetData.wlSlug = data.whitelabel_slug;
          widgetData.merchantSlug = data.merchant_slug;
          const expectedOrigin = `${process.env.REACT_APP_UI_URL}`.replace(/wlslug/, widgetData.wlSlug);
          widgetData.expectedOrigin = expectedOrigin;
          var iframe = document.createElement('iframe');
          iframe.id = "widget-iframe";
          iframe.style.minWidth = '330px';
          iframe.style.minHeight = '400px';
          iframe.style.width = '100%';
          iframe.style.height = '100%';
          iframe.style.border = '1px solid #d3d3d3';
          iframe.src = widgetData.urlOverride ? `${expectedOrigin}/${widgetData.merchantSlug}/${widgetData.urlOverride}` : `${expectedOrigin}/${widgetData.merchantSlug}/borrowerExperience/widget?${parseData(formData)}`;
          container.innerHTML = '';
          container.appendChild(iframe);

          // Listen for message events
          window.addEventListener('message', function (event) {
            if (event.origin === expectedOrigin && callback) {
              callback(event.data);
            }
          }, false);

        } else {
          displayEnableWidgetError(widgetData.invalidClientKey);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        displayEnableWidgetError(widgetData.invalidClientKey);
      });
  }
}

function displayEnableWidgetError(errorMessage) {
  var container = document.getElementById(widgetData.containerId);
  if (container) {
    const span = document.createElement('span');
    span.style.color = 'red';
    span.style.fontSize = '30px';
    span.innerText = errorMessage;
    container.appendChild(span);
  } else {
    alert(errorMessage);
  }
}

/**
 * Opens an existing loan application
 * @param {string} applicationId - Application Id.
 * @param {string} vuid - Visitor pass token.
 */
function open(applicationId, vuid) {
  if (!widgetData.containerFound) {
    const containerErrorMessage = 'Container not found: ' + widgetData.containerId;
    displayEnableWidgetError(containerErrorMessage);
  } else {
    const iframe = document.getElementById('widget-iframe');
    if (iframe) {
      if (applicationId && vuid) {
        iframe.src = `${widgetData.expectedOrigin}/${widgetData.merchantSlug}/borrowerExperience/widget/${applicationId}/${vuid}?${(new Date()).getTime()}`;
      } else {
        displayEnableWidgetError('Application id and vuid are required to open an existing app');
      }
    } else {
      displayEnableWidgetError('Iframe not found: widget-iframe');
    }
  }
}

/**
 * Opens an existing application without the need to first call setup.
 * @param {string} clientKey - Merchant client key.
 * @param {string} containerId - The ID of the container where the iframe should be appended.
 * @param {string} applicationId - Application ID.
 * @param {string} vuid - Visitor pass token.
 */
function openWithKey(clientKey, containerId, applicationId, vuid) {
  // resets the widgetData obj
  widgetData.expectedOrigin = '';
  widgetData.clientKey = '';
  widgetData.wlSlug = '';
  widgetData.merchantSlug = '';
  widgetData.containerFound = false;
  widgetData.containerId = '';
  widgetData.urlOverride = `borrowerExperience/widget/${applicationId}/${vuid}?${(new Date()).getTime()}`;
  setup(clientKey, containerId, null, null);
}

/**
 * Creates a new application with/without pre-filled data.
 * @param {any} data Applicant's data.
 */
function newApp(data) {
  if (!data) { data = {} };
  if (!widgetData.containerFound) {
    const containerErrorMessage = 'Container not found: ' + widgetData.containerId;
    displayEnableWidgetError(containerErrorMessage);
  } else {
    const iframe = document.getElementById('widget-iframe');
    if (iframe) {
      iframe.src = `${widgetData.expectedOrigin}/${widgetData.merchantSlug}/borrowerExperience/widget?${parseData(data)}`;
    } else {
      displayEnableWidgetError('Iframe not found: widget-iframe');
    }
  }
}

function parseData(data) {
  let querystring = "";
  if (data) {
    // make sure ssn and dob are excluded from the object
    if (data.date_of_birth) {
      delete data.date_of_birth;
    }
    if (data.ssn) {
      delete data.ssn;
    }
    querystring = new URLSearchParams(data).toString();
  }
  return `${querystring}&${(new Date()).getTime()}`;
}

export { setup, open, newApp, openWithKey };